p,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    color: $color-black;
    font-family: $font-Poppins;
}
h1{
    font-size: rem(60);
}
h4{
    font-size: rem(24)
}
h5{
    font-size: rem(21)
}
h6{
    font-size: rem(15)
}
p{
    font-size: rem(14)
}
