// font
@mixin font-face($font) {
    font-family: $font;
}

// media query
@mixin media($width) {
    @media only screen and (min-width: $width+px) {
        @content;
    }
}

// placeholder
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}