// fonts
$base-unit: 16;
$font-all: "Poppins",
"Merriweather",
Arial,
sans-serif;

$font-Merri: 'Merriweather',
Arial,
sans-serif;

$font-Poppins: 'Poppins',
Arial,
sans-serif;
