/*======================= Hero =======================*/

.hero {
    background: linear-gradient(120deg, #EDFDFE, #7CE0E7);
    margin-bottom: 35px;
    padding: 35px 0 70px;
    position: relative;
    width: 100%;
    z-index: 1;
    @include media(768) {
        padding: 70px 0;
    }
    @include media(992) {
        padding-bottom: 0;
        margin-bottom: 100px;
        height: 1200px;
    }
    &:after {
        @include media(992) {
            background-image: url(..//../../../img/hero.png);
            background-position: center bottom;
            background-repeat: no-repeat;
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
            transform: translateY(68px);
        }
    }
    &-inner {
        @include make-row();
    }
    &-wrapper {
        @include make-col-ready();
        @include make-col(12);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }
    /* hero-logo */
    &-logo {
        margin-bottom: 60px;
        @include media(576) {
            margin-bottom: 120px;
        }
    }
    /* progress */
    .progress {
        margin-bottom: 110px;
        ul {
            display: flex;
            justify-content: space-between;
            width: 200px;
            @include media(360) {
                width: 270px;
            }
            @include media(500) {
                width: 420px;
            }
            @include media(768) {
                width: 560px;
            }
            @include media(992) {
                width: 620px;
            }
        }
        li {
            flex: 1;
            position: relative;
            z-index: 2;
            &:last-child {
                flex: none;
                &:before {
                    border: 8px solid #85D8DD;
                }
            }
            &:not(:last-child) {
                &:after {
                    background: #26B6C0;
                    content: "";
                    height: 10px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: -1;
                }
            }
            &:before {
                content: "";
                position: absolute;
                left: -8px;
                top: -8px;
                background: #fff;
                border-radius: 100px;
                border: 8px solid #26B6C0;
                height: 25px;
                width: 25px;
                @include media(576) {
                    height: 30px;
                    width: 30px;
                    left: -10px;
                    top: -10px;
                }
            }
            &.disabled {
                &:after {
                    background: #DBF8FA;
                }
                &:before {
                    border: 8px solid #85D8DD;
                }
            }
        }
        &__info {
            color: $color-black;
            font-family: $font-Poppins;
            font-size: rem(14);
            font-weight: 400;
            position: absolute;
            left: 5px;
            top: 25px;
            transform: translateX(-50%);
        }
        .date {
            position: absolute;
            top: -50px;
            font-size: rem(11);
            font-weight: 400;
            color: #fff;
            background: #8AB31E;
            padding: 5px 10px;
            right: -38px;
            border-radius: 100px;
            @include media(576) {
                font-size: rem(12);
                padding: 8px 15px;
                right: -52px;
            }
            &:after {
                position: absolute;
                content: "";
                left: 50%;
                bottom: -8px;
                width: 10px;
                font-family: $font-Poppins;
                height: 10px;
                overflow: hidden;
                background: #8AB31E;
                text-align: center;
                transform: rotate(45deg) translateX(-50%);
                border-radius: 2px;
            }
        }
        .ongoing {
            .date {
                right: -38px;
                @include media(576) {
                    right: -50px;
                }
                background: #EC4A0D;
                &:after {
                    background: #EC4A0D;
                }
            }
        }
    }
    /* optin-form */
    .optin-form {
        h4 {
            color: $color-black;
            font-weight: 400;
            margin-bottom: 50px;
        }
        .checkbox__area {
            margin-top: 20px;
            .checkbox {
                input[type="checkbox"] {
                    display: none;
                }
                input[type='checkbox']+label {
                    position: relative;
                    display: flex;
                    margin: .6em 0;
                    align-items: center;
                    transition: color 250ms cubic-bezier(.4, .0, .23, 1);
                }
                .check {
                    position: relative;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #b4b4b4;
                    background: #fff;
                    overflow: hidden;
                    z-index: 1;
                    border-radius: 3px;
                    &:before {
                        position: absolute;
                        content: "";
                        transform: rotate(45deg);
                        display: block;
                        margin-top: -3px;
                        margin-left: 7px;
                        width: 0;
                        height: 0;
                        animation: checkbox-off 0.3s forwards;
                    }
                }
                input[type='checkbox']:checked+label>.check {
                    background: #fff;
                }
                input[type='checkbox']:checked+label>span:before {
                    color: #8AB31E;
                    border-color: #8AB31E;
                    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
                    animation: checkbox-on 0.3s forwards;
                } // keyframes
                @keyframes checkbox-on {
                    0% {
                        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
                    }
                    50% {
                        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
                    }
                    100% {
                        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
                    }
                }
            }
        }
        .ss-form {
            margin-bottom: 20px;
            @include media(768) {
                width: 465px;
            }
        }
        span {
            color: #666C75;
            font-size: rem(14);
            font-weight: 400;
        }
    }
}

/* Products */

.products {
    .our-works {
        position: fixed;
        right: -10px;
        top: 80px;
        transform: rotate(90deg) translateY(-45px);
        z-index: 999;
        @include media(576) {
            top: 40%;
        }
        button {
            background: transparent;
            color: $color-white;
            font-size: rem(15);
            font-family: $font-Poppins;
            font-weight: 500;
            @include media(576) {
                text-transform: uppercase;
                font-size: rem(16);
            }
        }
    }
    /* Products items */
    &__items {
        background: #fff;
        box-shadow: 0 0 44px rgba(42, 46, 48, .23);
        height: 100%;
        position: fixed;
        padding: 30px 40px 0;
        right: -450px;
        top: 0;
        overflow-y: scroll;
        transition: .5s;
        visibility: hidden;
        width: 300px;
        z-index: 999;
        @include media(576) {
            width: 390px;
            padding: 65px 40px 0;
        }
        &-visible {
            right: 0;
            visibility: visible;
        }
        .title {
            padding-bottom: 45px;
            h4 {
                color: $color-cyan;
                font-weight: 500;
            }
        }
        .product__single-item {
            margin-bottom: 45px;
            .product__item-img {
                border-radius: 5px;
                margin-bottom: 28px;
                width: 100%;
                overflow: hidden;
                border: 1px solid #979797;
                border-radius: 5px;
                img {
                    transition: .3s;
                }
                &:hover img {
                    transform: scale(1.1);
                }
            }
            h6 {
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1px;
                a {
                    color: $color-black;
                    transition: .3s;
                    &:hover {
                        color: $color-cyan;
                    }
                }
            }
        }
        .close__btn {
            color: #000;
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            right: 40px;
            top: 30px;
            @include media(576) {
                top: 55px;
            }
        }
    }
}

/*===================== Inner Page =====================*/

.inner-pages {
    @include media(992) {
        height: 100vh;
    }
    @media(min-height:720px) and (max-height:850px) {
        height: 950px;
    }
    .hero-content {
        max-width: 650px;
        margin-bottom: 45px;
        h1 {
            margin-bottom: 15px;
            font-size: rem(35);
            @include media(768) {
                font-size: rem(45);
            }
            @include media(992) {
                font-size: rem(60);
            }
        }
        .success__msg {
            font-weight: 400;
            line-height: 28px;
            color: #666C75;
            strong {
                font-weight: 700;
            }
            a {
                color: #666C75;
                transition: .3s;
                &:hover {
                    color: $color-cyan;
                }
            }
        }
    }
}
