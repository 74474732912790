body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @include font-face($font-all);
    color: $color-black;
}
html {
    font-size: 87.5%;
}
@include media(768) {
    html {
        font-size: 100%;
    }
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

:focus {
    outline: none;
}

a {
    text-decoration: none;
    display: inline-block;
}

ul,
ol {
    list-style: none;
    padding-left: 0;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

button {
    cursor: pointer;
    padding: 0;
    border: 0;
    &:focus {
        outline: 0;
    }
}

span {
    display: inline-block;
}

input,
textarea {
    @include input-placeholder {
        color: #888;
    }
    padding: 0;
    &:focus {
        outline: 0;
    }
}

table {
    border-collapse: collapse;
}
