.footer {
    padding-bottom: 65px;
    padding-top: 8px;
    &-inner{
        @include make-row();
    }
    .copyright{
        @include make-col-ready();
        @include make-col(12);
        text-align: center;
        p{
            color: #666C75;
            font-size: rem(14);
            font-weight: 400;
        }
    }
}
