.ss-form{
    .btn-submit {
        background: $color-cyan;
        width: 100%;
        font-family: $font-Poppins;
        font-weight: 600;
        font-size: rem(17);
        color: $color-white;
        text-transform: uppercase;
        padding: 25px 40px;
        transition: .3s;
        
        &:hover {
            background: darken($color-cyan, 5%);
        }
    }
}
.form__field {
    input {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.13);
        border: 0;
        text-align: center;
        width: 100%;
        font-family: $font-Poppins;
        font-size: rem(18);
        font-weight: 400;
        padding: 25px 0;
        margin-bottom: 10px;
        transition: .3s;
        &::placeholder {
            color: rgba(#666C75, .5);
        }
        &:focus {
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.13), 0 0 0 1px $color-cyan;
        }
    }
}

/* checkbox__area */
.checkbox__area{
    text-align: left;
    position: relative;
    .checkbox{
        margin-bottom: 35px;
        display: flex;
        align-items: center;
    }
    .checkbox__text{
        color: #666C75;
        font-size: rem(15);
        font-weight: 400;
        margin-left: 12px;
        a{
            color: $color-cyan;
        }
    }
    .checkbox__modal{
        background: #2D3136;
        padding: 20px;
        border-radius: 3px;
        left: 12px;
        opacity: 0;
        position: absolute;
        bottom: 0;
        max-width: 340px;
        transform: translateY(-58px);
        transition: .3s;
        visibility: hidden;
        &-visible{
            opacity: 1;
            transform: translateY(-38px);
            visibility: visible;
        }
        &::after{
            background: #2D3136;
            bottom: -8px;
            content: "";
            height: 20px;
            left: 50%;
            margin-left: -10px;
            overflow: hidden;
            position: absolute;
            transform: rotate(45deg);
            width: 20px;
        }
        h6{
            color: $color-white;
            font-size: rem(14);
            font-weight: 500;
            margin-bottom: 5px;
        }
        p{
            color: $color-white;
            font-weight: 400;
            font-family: $font-Poppins;
            line-height: 20px;
        }
    }
}